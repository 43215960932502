import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Card extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div className="card">{this.props.children}</div>;
  }
}

Card.propTypes = {
  //props
  children: PropTypes.object,
};

export default Card;
