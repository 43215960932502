import { axiosDefault, handleErrors } from '../utils/defaults';

const notificationService = {
  getList: async () => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.get('/api/webhook');
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  get: async (id) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.get(`/api/ml-details/${id}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },
};

export default notificationService;
