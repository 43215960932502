export const colors = {
  primaryOne: '#023874',
  primaryTwo: '#0079BA',
  primaryThree: '#1A1A1A',
  secondaryOne: '#FFFFFF',
  secondaryTwo: '#707070',
  secondaryThree: '#358E2E',
  supportOne: '#FAFAFA',
  supportTwo: '#E9E9E9',
  supportThree: '#E8F6FF',
  white: '#FFFFFF',
  black: '#000000',
  lightGrey: '#D9D9D9',
  gray: '#75787B',
  lightGreyTwo: '#F0F0F0',
  red: '#F00000',
  silver: '	#C0C0C0',
  platinum: '#E5E4E2',
  gold: '#FFD700',
};
