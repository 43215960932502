import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../utils/devices';

export const Text = styled.p`
  font-size: 20px;
  line-height: 34px;
  margin: 10px 0 45px;
  word-break: ${({ doc }) => (doc ? 'break-all' : 'normal')};

  ${({ stayIn }) =>
    stayIn
      ? `
    color: ${colors.secondaryTwo};
    font-size: 20px;
    line-height: 30px;
    margin: 10px 0 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    `
      : ''};

  @media ${bootstrapMax.lg} {
    ${({ home, stayIn }) =>
      stayIn && !home
        ? ''
        : !home
        ? 'margin: 25px 0 10px;'
        : 'margin: 0 0 55px 0; text-align: center'};

    ${({ home }) => (!home ? 'margin: unset i' : '0 0 55px 0; text-align: center')};
    line-height: 30px;
  }
`;

export const TextLink = styled.a`
  text-decoration: ${({ footer }) => (!footer ? 'underline' : 'none')} !important;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 16)}px !important;
  color: ${colors.white} !important;
  ${({ footer }) =>
    footer
      ? `font-family: 'Trade Gothic LT Bold' !important;`
      : `margin: 10px 0; &:hover {
      color: ${colors.primaryOne} !important;
    }`}
`;

export const TextMenu = styled.span`
  color: ${colors.white} !important;
  font-size: 16px !important;
  margin: 10px 0;
`;

export const TitleApp = styled.span`
  font-size: 24px;
  text-transform: uppercase;

  @media ${bootstrapMax.lg} {
    font-size: 16px;
  }
`;

export const TitleApp2 = styled.span`
  font-size: 22px;
  line-height: 40px;
  text-transform: uppercase;
  color: ${colors.primaryThree};

  @media ${bootstrapMax.lg} {
    font-size: 20px;
  }
`;

export const TitleApp3 = styled.span`
  font-size: 18px;
  line-height: 40px;
  text-transform: uppercase;
  color: ${colors.primaryThree};

  @media ${bootstrapMax.lg} {
    font-size: 16px;
    line-height: 23px;
  }
`;

const defaultParagraph = styled.p`
  text-align: left;

  a {
    color: ${colors.primaryOne};
  }
`;

export const Paragraph1 = styled(defaultParagraph)`
  font-size: 24px;
  line-height: 36px;
  color: ${({ lightColor }) => (lightColor ? colors.white : colors.primaryThree)};
  font-family: ${({ lightFont }) => (lightFont ? 'Trade Gothic LT Light' : 'Trade Gothic LT')};
  text-align: ${({ center }) => (center ? 'center' : 'left')};

  @media ${bootstrapMax.lg} {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const Paragraph2 = styled(defaultParagraph)`
  font-size: 20px;
  line-height: 30px;
  margin: 0 0 35px;
  ${({ center }) => (center ? 'text-align: center;' : '')}

  @media ${bootstrapMax.lg} {
    margin: 0 0 25px;
  }

  @media ${bootstrapMax.sm} {
    text-align: left;
  }
`;

export const Paragraph3 = styled(defaultParagraph)`
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  font-size: 18px;
  line-height: 24px;
  color: ${({ lightColor }) => (lightColor ? colors.white : colors.secondaryTwo)};

  @media ${bootstrapMax.lg} {
    font-size: 14px;
    line-height: 24px;
  }
`;

export const Paragraph4 = styled.p`
  font-size: ${({ fontSizeMobile }) => (fontSizeMobile ? fontSizeMobile : 16)}px;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : 24)}px;
  color: ${({ lightColor }) => (lightColor ? colors.white : colors.primaryThree)};
  ${({ borderLeft }) =>
    borderLeft &&
    `border-left: 2px solid ${colors.primaryTwo}; padding-left: 5px; margin-bottom: 38px; 
    @media ${bootstrapMin.lg} { padding-left: 12px; margin-bottom: 23px; }`}

  @media ${bootstrapMin.lg} {
    font-size: 16px;
  }
`;

export const ProductsOrdination = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.secondaryTwo};
  font-family: 'Trade Gothic LT Bold';
  text-align: end;
  padding: 0 14px;
  margin-bottom: -5px;

  @media ${bootstrapMax.lg} {
    font-size: 14px;
    color: ${colors.secondaryTwo};
    font-family: 'Trade Gothic LT Bold';
  }

  @media ${bootstrapMax.md} {
    font-family: 'Trade Gothic LT';
    font-size: 12px;
  }
`;

export const Paragraph5 = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${({ lightColor }) => (lightColor ? colors.white : colors.primaryThree)};

  @media ${bootstrapMax.lg} {
    line-height: 24px;
  }
`;

export const TitleHeading = styled.h2`
  font-size: 38px;
  line-height: 54px;
  text-transform: uppercase;

  @media ${bootstrapMax.lg} {
    font-size: 32px;
    line-height: 36px;
  }
`;

export const TitleSearch = styled.span`
  font-size: 38px;
  line-height: 54px;
  text-align: center;
  margin-top: 30px;
  color: ${colors.primaryOne};

  span {
    text-transform: uppercase;
  }

  @media ${bootstrapMax.lg} {
    font-size: 32px;
    line-height: 36px;
  }
`;

const defaultHeading = styled.div`
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  white-space: ${({ noWrap }) => (noWrap ? 'normal' : 'nowrap')};
`;

export const Heading1 = styled(defaultHeading)`
  align-items: start;
  flex-direction: column;

  h1:first-child {
    font-size: 42px;
    border: none;
    color: ${({ lightColor }) => (lightColor ? colors.white : colors.primaryOne)};
    line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : 40)}px;
  }

  h1:last-child {
    font-size: 24px;
    line-height: 24px;
    color: ${colors.primaryTwo};
  }

  @media ${bootstrapMax.lg} {
    h1:first-child {
      font-size: 25px;
      line-height: 35px;
    }

    h1:last-child {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @media (max-width: 376px) {
    h1:last-child {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export const Heading2 = styled(defaultHeading)`
  font-family: 'Trade Gothic LT Bold';
  align-items: center;
  flex-direction: column;

  h2:first-child {
    font-size: 42px;
    border: none;
    color: ${({ lightColor }) => (lightColor ? colors.white : colors.primaryOne)};
    line-height: 40px;
    text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  }

  h2:last-child {
    font-size: 24px;
    line-height: 24px;
    color: ${colors.primaryTwo};
    text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  }

  @media ${bootstrapMax.lg} {
    h2:first-child {
      font-size: 25px;
      line-height: 35px;
    }

    h2:last-child {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @media (max-width: 376px) {
    h2:last-child {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export const Heading3 = styled(defaultHeading)`
  flex-direction: column;

  h1:first-child {
    font-size: 30px;
    line-height: 24px;
  }

  h1:last-child {
    font-size: 42px;
    line-height: 48px;
  }

  @media ${bootstrapMax.lg} {
    h1:first-child {
      font-size: 20px;
      ${({ oneLine }) => (oneLine ? '' : 'line-height: 14px;')}
    }

    h1:last-child {
      font-size: 32px;
      line-height: 36px;
    }
  }

  h1 {
    white-space: break-spaces;
    word-wrap: break-word;
  }
`;

export const Heading4 = styled(defaultHeading)`
  font-family: 'Trade Gothic LT';
  color: ${colors.primaryTwo};
  align-items: center;
  flex-direction: column;
`;

export const Heading5 = styled(defaultHeading)`
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.primaryTwo};
  align-items: center;
  flex-direction: column;
  font-weight: bold;
`;

export const Label = styled.span`
  font-size: 12px;
  text-transform: uppercase;

  ${({ stayIn }) =>
    stayIn
      ? `
    color: ${colors.secondaryTwo};
    font-size: 14px;
    text-transform: none;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    `
      : ''};
`;

export const LabelAccordion = styled.span`
  font-size: ${({ mini, faq }) => (mini ? 16 : faq ? 26 : 20)}px;
  text-transform: uppercase;

  @media ${bootstrapMax.sm} {
    font-size: ${({ mini }) => (mini ? 16 : 14)}px;
  }
`;

export const TitlePlatformCard = styled.span`
  font-size: 20px;
  margin-bottom: 12px;
`;

export const LabelContact = styled.span`
  font-size: 20px;
  line-height: 30px;

  @media ${bootstrapMax.lg} {
    font-size: 14px;
  }
`;
export const TitleWhite = styled(Paragraph4)`
  font-size: 32px;
  text-align: left;
  margin-bottom: 10px;
  color: ${colors.white};

  @media ${bootstrapMax.lg} {
    font-size: ${({ fontSizeMobile }) => (fontSizeMobile ? fontSizeMobile : 20)}px;
    text-align: ${({ textAlignMobile }) => (textAlignMobile ? textAlignMobile : 'left')};
  }
`;
export const LabelHome = styled.span`
  display: block;
  font-size: 14px;
  max-width: 110px;
  height: 21px;
  line-height: 1rem;
  margin-top: 5px;
  text-transform: lowercase;
`;

export const ModalTitle = styled.span`
  font-size: 32px;
  text-transform: uppercase;
  color: ${colors.primaryOne};
`;
export const ModalContent = styled.p`
  color: ${colors.primaryOne};
  font-size: 20px;
  line-height: 30px;

  @media ${bootstrapMax.lg} {
    font-size: 16px;
    text-align: left;
    line-height: 20px;
  }
`;

export const Message = styled.p`
  font-size: 12px;
  color: ${colors.secondaryThree};
  font-family: 'Trade Gothic LT';
  font-weight: bolder;
`;
