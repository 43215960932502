import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { SubHeaderBack, RowStyled as Row, RowBackStyled, ColStyled } from './styles';
import { Text } from '../../components';
import Button from '../Button';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

class SubHeader extends Component {
  render() {
    // props
    const { onClick, minHeight, minHeightSm, backgroundColor, title } = this.props;

    // types
    const { back, alignCenter } = this.props;

    if (back) {
      return (
        <>
          <RowBackStyled>
            <ColStyled
              className={'align-self-center px-4 px-lg-2 back'}
              sm={{ offset: 2 }}
              lg={{ offset: 1 }}
              xl={{ offset: 0 }}>
              <Button back onClick={onClick} />
            </ColStyled>
          </RowBackStyled>
          <SubHeaderBack
            minHeight={minHeight}
            minHeightSm={minHeightSm}
            backgroundColor={backgroundColor}
          />
        </>
      );
    }

    return (
      <>
        {title ? (
          <Row className={'mt-4 mt-md-5 pt-md-4 mb-md-3 pb-md-3 m-0'}>
            <Col className={'justify-content-center d-flex mt-1'}>
              <Text
                heading1
                className={`${alignCenter ? 'align-items-center' : ''}`}
                title={{ one: title }}
              />
            </Col>
          </Row>
        ) : (
          ''
        )}
      </>
    );
  }
}

SubHeader.propTypes = {
  // props
  onClick: PropTypes.func,
  minHeight: PropTypes.number,
  minHeightSm: PropTypes.number,
  backgroundColor: PropTypes.string,
  title: PropTypes.string,

  // types
  back: PropTypes.bool,
  alignCenter: PropTypes.bool,
};

export default SubHeader;
