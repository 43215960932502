import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'react-bootstrap';
import announcementService from '../../../services/announcements';
import { Screen, Text, Image, Label, Input, Button } from '../../../components';

const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
  baseURL,
} = require('../../../utils/defaults');

class AnnouncementEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Announcement: { price_min: '', difference: '' },
      ...defaultStates(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  async componentDidMount() {
    this.setState({ loadingState: true });

    const { id } = this.props.match.params;

    const response = await announcementService.admin.get(id);
    if (response.ok && response.data && response.data.Announcement) {
      this.setState({
        Announcement: response.data.Announcement,
      });
    } else {
      const modalState = {
        title: 'Erro',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }

    this.setState({ loadingState: false });
  }

  handleChange(id, event) {
    const { Announcement } = this.state;
    Announcement[id] = event.target.value;
    this.setState({ Announcement });
  }

  async handleSubmit() {
    const { Announcement } = this.state;
    this.setState({ loadingState: true });
    const response = await announcementService.admin.edit(Announcement);

    if (response.ok) {
      const alertScreenState = {
        title: 'Anúncio alterado com sucesso!',
        open: true,
        onClick: this.alertScreenOnClick,
        pagePath: `/admin/anuncio/${Announcement.id}`,
      };

      this.setState({ alertScreenState });
    } else {
      const modalState = {
        title: 'Erro',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };

      this.setState({ modalState });
    }

    this.setState({ loadingState: false });
  }

  body() {
    const { Announcement } = this.state;

    return (
      <>
        <Row align="center">
          <Col>
            <Text title text={'Mercado Livre'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              value={Announcement.title}
              label={'Título:'}
              placeholder={'Título:'}
              maxLength={200}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              value={Announcement.price ? String(Announcement.price) : ''}
              label={'Preço de venda:'}
              placeholder={'Preço de venda:'}
              maxLength={8}
              disabled
            />
          </Col>
        </Row>
        <hr />
        <br />
        <Row align="center">
          <Col>
            <Text title text={'Configurações de Automatização'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              value={Announcement.price_min ? String(Announcement.price_min) : ''}
              label={'Preço mínimo:'}
              placeholder={'Preço mínimo:'}
              type="number"
              maxLength={8}
              onChange={(e) => this.handleChange('price_min', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              value={Announcement.difference ? String(Announcement.difference) : ''}
              label={'Diferença:'}
              placeholder={'Diferença:'}
              type="number"
              maxLength={8}
              onChange={(e) => this.handleChange('difference', e)}
            />
          </Col>
        </Row>
        <br />
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center">
          <Col>
            <Button text={'Voltar'} onClick={() => this.props.history.goBack()} />
          </Col>
          <Col>
            <Button text={'Salvar'} onClick={this.handleSubmit} />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <>
        <Screen
          admin
          body={this.body}
          footer={this.footer}
          modalState={modalState}
          alertScreenState={alertScreenState}
          loadingState={loadingState}
        />
      </>
    );
  }
}

AnnouncementEdit.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default AnnouncementEdit;
