const { dataFormParse } = require('../utils/dataFormParse');
const { axiosDefault, handleErrors } = require('../utils/defaults');

const photoService = {
  admin: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/photo/admin/${id ? id : ''}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async () => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/photo/admin`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    save: async (photo) => {
      try {
        const axios = axiosDefault('file');
        const { data } = await axios.post(`/api/photo/admin`, dataFormParse(photo));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    edit: async (photo) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.put(`/api/photo/admin/${photo.id}`, dataFormParse(photo));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    delete: async (id) => {
      try {
        const axios = axiosDefault();

        const { data } = await axios.delete(`/api/photo/admin/${id}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },

  user: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/photo/${id}`);

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async ({ limit = '', search = '', descOrder = '', offset = '', random = '' }) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(
          `/api/photo?limit=${limit}&title=${search}&descOrder=${descOrder}&offset=${offset}&random=${random}`,
        );

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },
};

export default photoService;
