import styled from 'styled-components';
import { bootstrapMax } from '../../utils/devices';

export const Logo = styled.a`
  flex: 1;
  width: 160px;
  height: 40px;
  display: flex;
  justify-content: start;

  div {
    width: 160px;
    height: 40px;
    background-image: url(${({ img }) => img});
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  @media ${bootstrapMax.lg} {
    width: 129px;
    height: 32px;
    justify-content: center;

    div {
      width: 129px;
      height: 32px;
      margin: 0;
    }
  }
`;
