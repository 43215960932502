import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Screen, Text } from '../../../components';
import { Row, Col } from 'react-bootstrap';
import MUIDataTable from 'mui-datatables';

import notificationService from '../../../services/notifications';

const { defaultStates, defaultAlertScreenOnClick } = require('../../../utils/defaults');

class MlNotificationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      notificationsList: [],
      ...defaultStates(),
    };

    this.modalOnHide = this.modalOnHide.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
  }

  async componentDidMount() {
    this.setState({ loadingState: true });
    const response = await notificationService.getList();

    if (response.ok && response.data) {
      const data = response.data;
      data.map((item) => {
        item.sent = new Date(item.sent).toLocaleDateString('pt-BR');
        return item;
      });
      this.setState({ notificationsList: data });
      this.setState({ loadingState: false });
    } else {
      const modalState = {
        title: 'Erro',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };

      this.setState({ loadingState: false });
      this.setState({ modalState });
    }
  }

  modalOnHide() {
    const { modalState } = this.state;
    modalState.toggle = false;
    this.setState(modalState);
    this.fillTable(false, this.state.sellerML);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  body() {
    const columns = [
      {
        name: 'id',
        options: {
          display: 'excluded',
        },
      },
      {
        name: '_id',
        label: 'Código',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'resource',
        label: 'Recurso',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'user_id',
        label: 'Usuário',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'topic',
        label: 'Tópico',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'sent',
        label: 'Data de Envio',
        options: {
          filter: true,
          sort: false,
        },
      },
    ];

    const optionsMui = {
      filterType: 'checkbox',
      selectableRows: 'none',
      responsive: 'standard',
      print: false,
      onRowClick: (rowData) => {
        this.props.history.push(`/admin/notifications/${rowData[2].substr(8)}`);
      },
    };

    return (
      <>
        <Row align={'center'}>
          <Col>
            <Text title text={'Notificações Mercado Livre'} />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="mt-3">
            <MUIDataTable
              title={'Notificações'}
              data={this.state.notificationsList}
              columns={columns}
              options={optionsMui}
            />
          </Col>
        </Row>
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center"></Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        admin
        body={this.body}
        footer={this.footer}
        modalState={modalState}
        loadingState={loadingState}
        alertScreenState={alertScreenState}
      />
    );
  }
}

MlNotificationsList.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default MlNotificationsList;
