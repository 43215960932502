import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CloseButton, PreviewDoc, LogoImg, Banner, PreviewImg, CustomImg } from './styles';
import close_maskavo_min from '../../assets/icons/admin/close_maskavo-min@2x.png';
import document from '../../assets/icons/admin/document_maskavo-min.png';
import document2x from '../../assets/icons/admin/document_maskavo-min@2x.png';
import logoSrc from '../../assets/logos/Admin.png';
import { Col, Row } from 'react-bootstrap';
import Text from '../Text';

import { width } from '../../utils/devices';

class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= width.lg) {
        this.setState({ zoom: false });
      }
    });
  }

  zoomImage = () => {
    this.setState({ zoom: !this.state.zoom });
  };

  render() {
    // props
    const { img, label, onClick, alt, className } = this.props;

    // types
    const { doc, logo, preview } = this.props;

    if (doc) {
      return (
        <PreviewDoc>
          <Row className={'flex-column align-items-center'} style={{ width: '100%' }}>
            <Col align="center">
              <CloseButton onClick={onClick} img={close_maskavo_min} className={'close-button'} />
              <img
                src={img.id ? img.urlS3 : document}
                srcSet={img.id ? img.urlS3 : document2x}
                alt=""
                onClick={() => {
                  onClick;
                }}
                className="mb-2"
              />
            </Col>
            <Col xs={8}>
              <Text subTitle doc text={label} />
            </Col>
          </Row>
        </PreviewDoc>
      );
    }

    if (logo) {
      return <LogoImg src={logoSrc} />;
    }

    if (preview) {
      return (
        <PreviewImg className="mb-1 mt-3">
          <CustomImg src={img} alt="" />
        </PreviewImg>
      );
    }

    return <Banner src={img} alt={alt} className={className} />;
  }
}

Image.propTypes = {
  // props
  img: PropTypes.string,
  imgSet: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,

  // types
  doc: PropTypes.bool,
  alt: PropTypes.any,
  logo: PropTypes.bool,
  preview: PropTypes.bool,
};

export default Image;
