import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'react-bootstrap';
import announcementService from '../../../services/announcements';
import { Screen, CardAnnouncement } from '../../../components';

const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
  baseURL,
} = require('../../../utils/defaults');

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Announcement: {},
      ...defaultStates(),
    };

    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
  }

  async componentDidMount() {
    this.setState({ loadingState: true });

    const { id } = this.props.match.params;

    const resAnnouncement = await announcementService.admin.getDetails(id);
    if (resAnnouncement.ok && resAnnouncement.data) {
      this.setState({
        Announcement: resAnnouncement.data,
      });
    }

    this.setState({ loadingState: false });
  }

  body() {
    const { Announcement } = this.state;

    return (
      <>
        <Row>
          <CardAnnouncement
            title={Announcement.title}
            id_ml={Announcement.id_ml}
            category_id={Announcement.category_id}
            price={Announcement.price}
            available_quantity={Announcement.available_quantity}
            listing_type_id={Announcement.listing_type_id}
            permalink={Announcement.permalink}
            secure_thumbnail={Announcement.secure_thumbnail}
            shipping_mode={Announcement.shipping_mode}
            history={this.props.history}
          />
        </Row>
        <br />
      </>
    );
  }

  footer() {
    return <></>;
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <>
        <Screen
          admin
          body={this.body}
          footer={this.footer}
          modalState={modalState}
          alertScreenState={alertScreenState}
          loadingState={loadingState}
        />
      </>
    );
  }
}

Details.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
  Announcements: PropTypes.array,
};

export default Details;
