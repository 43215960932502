import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input as InputComponent,
  InputTransparent,
  InputBrown,
  BigInputBrown,
  Flex,
  InputSearch,
  InputMargin,
  InputDisabled,
  InputSearchImg,
  InputBlack,
  InputBlackPassword,
  InputSearchBlack,
  InputCloseImg,
  InputSearchWhite,
  InputSearchNews,
  InputRounded,
  Rounded,
  Search,
  RequiredIcon,
  Textarea,
  SpanError,
} from './style';
import { Label } from '../index';
import search_icon from './../../assets/icons/admin/search_white@2x-min.png';
import search_icon_blue from './../../assets/icons/admin/noun_Search_1576876.png';
import close_icon from './../../assets/icons/admin/close_gray-min@2x.png';
import { Col, Row } from 'react-bootstrap';

class Input extends Component {
  render() {
    //props
    const {
      placeholder,
      label,
      value = '',
      onChange,
      type = 'text',
      onKeyPress,
      onSearch,
      onClickClose,
      maxLength,
      onClick,
      fontSize,
      width,
      height,
      id,
    } = this.props;

    //types
    const {
      transparent,
      inputBrown,
      search,
      margin,
      disabled,
      password,
      inputBlack,
      passwordBlack,
      recoveryPassword,
      bigInput,
      searchWhite,
      searchNews,
      rounded,
      border,
      required,
      textarea,
      footer,
      error,
    } = this.props;

    if (rounded) {
      return (
        <Rounded width={width ? width : 307}>
          {textarea ? (
            <>
              {required && <RequiredIcon style={{ top: '33px' }} />}
              <Textarea
                id={id}
                value={value}
                placeholder={placeholder}
                maxLength={maxLength}
                onChange={onChange}
                error={error}></Textarea>
            </>
          ) : (
            <>
              {required && <RequiredIcon />}
              <InputRounded
                tabIndex={0}
                id={id}
                type={type}
                border={border}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                maxLength={maxLength}
                onKeyPress={onKeyPress}
                required={required}
                width={width ? width : 307}
                height={height ? height : 43}
                fontSize={fontSize ? fontSize : 12}
                footer={footer}
                error={error}
              />
            </>
          )}
          {error && (
            <Row style={{ width: '100%' }}>
              <Col className={'d-flex justify-content-end p-0'}>
                <SpanError>Preencha o campo</SpanError>
              </Col>
            </Row>
          )}
        </Rounded>
      );
    }

    if (disabled) {
      return (
        <Flex>
          {label && <Label text={label} />}
          <InputComponent
            disabled={true}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    if (transparent) {
      return (
        <Flex>
          {label && <Label text={label} />}
          <InputTransparent
            id={type === 'email' && 'email'}
            type={type}
            value={value}
            placeholder={placeholder}
            onKeyPress={onKeyPress}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    if (inputBrown) {
      return (
        <Flex>
          {label && <Label text={label} />}
          <InputBrown
            type={type}
            placeholder={placeholder}
            value={value}
            onKeyPress={onKeyPress}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    if (search) {
      return (
        <Flex>
          <InputSearchBlack
            type={type}
            placeholder={placeholder}
            value={value}
            onKeyPress={(e) => {
              e.key === 'Enter' && onSearch();
            }}
            //onBlur={onSearch}
            onChange={onChange}
            maxLength={maxLength}
          />
          <InputSearchImg src={search_icon} onClick={onSearch} />
          <InputCloseImg src={close_icon} onClick={onClickClose} />
        </Flex>
      );
    }

    if (searchWhite) {
      return (
        <Search>
          <InputSearchImg img={search_icon_blue} />
          <InputSearchWhite
            type={type}
            placeholder={placeholder}
            value={value}
            onKeyPress={onKeyPress}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Search>
      );
    }

    if (searchNews) {
      return (
        <Search>
          <InputSearchNews
            type={type}
            placeholder={placeholder}
            value={value}
            onKeyPress={onKeyPress}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Search>
      );
    }

    if (margin) {
      return (
        <Flex>
          {label && <Label text={label} />}
          <InputMargin
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    if (password) {
      return (
        <Flex>
          {label && <Label text={label} />}
          <InputComponent
            placeholder={placeholder || 'Senha'}
            value={value}
            type={'password'}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    if (recoveryPassword) {
      return (
        <Flex>
          {label && <Label whiteLabel text={label} />}
          <InputTransparent
            placeholder={placeholder || 'Senha'}
            value={value}
            type={'password'}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    if (inputBlack) {
      return (
        <Flex>
          {label && <Label text={label} />}
          <InputBlack
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    if (passwordBlack) {
      return (
        <Flex>
          {label && <Label text={label} />}
          <InputBlackPassword
            placeholder={placeholder || 'Senha'}
            value={value}
            type={'password'}
            onChange={onChange}
            app
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    if (bigInput) {
      return (
        <Flex>
          {label && <Label text={label} />}
          <BigInputBrown
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    return (
      <Flex>
        {label && <Label text={label} />}
        <InputComponent
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
        />
      </Flex>
    );
  }
}

Input.propTypes = {
  //props
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  type: PropTypes.string,
  onKeyPress: PropTypes.func,
  onClickClose: PropTypes.func,
  onClick: PropTypes.func,
  maxLength: PropTypes.number,
  fontSize: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  id: PropTypes.any,

  //types

  inputBrown: PropTypes.bool,
  transparent: PropTypes.bool,
  search: PropTypes.bool,
  margin: PropTypes.bool,
  disabled: PropTypes.bool,
  password: PropTypes.bool,
  inputBlack: PropTypes.bool,
  passwordBlack: PropTypes.bool,
  recoveryPassword: PropTypes.bool,
  bigInput: PropTypes.bool,
  searchWhite: PropTypes.bool,
  searchNews: PropTypes.bool,
  rounded: PropTypes.bool,
  border: PropTypes.bool,
  required: PropTypes.bool,
  textarea: PropTypes.bool,
  footer: PropTypes.bool,
  error: PropTypes.bool,
};

export default Input;
