import React, { Component, Link } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import photoService from '../../../services/photo';
import { Screen, Text, ButtonUpload, Image, Label, Button } from '../../../components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
  baseURL,
} = require('../../../utils/defaults');

class Photos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photo: {
        files: [],
      },
      ...defaultStates(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  async componentDidMount() {
    this.setState({ loadingState: true });
    const { id } = this.props.match.params;

    if (id !== 'novo') {
      this.setState({ edit: true });
      const response = await photoService.admin.get(id);
      if (response.ok && response.data && response.data.photos) {
        this.setState({
          photo: response.data.photos,
          newRegister: false,
        });
      } else if (response.ok) {
        this.props.history.push('/admin/fotos/novo');
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  handleChange(id, event) {
    const { photo } = this.state;

    if (id === 'files') {
      photo[id] = [event.target.files && event.target.files[0]];
    } else if (id === 'removeFile') {
      photo.files = [];
      photo.file_id = null;
      this.setState({ newRegister: true });
    }

    this.setState({ photo });
  }

  async handleSubmit() {
    const { newRegister, photo } = this.state;
    this.setState({ loadingState: true });

    if (newRegister) {
      const response = await photoService.admin.save(photo);
      if (response.ok) {
        const alertScreenState = {
          title: 'Foto enviada com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/admin/fotos',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    } else {
      const response = await photoService.admin.edit(photo);

      if (response.ok) {
        const alertScreenState = {
          title: 'Foto alterada com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/admin/fotos',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  async handleDelete() {
    this.setState({
      modalState: {
        title: 'Remoção de foto',
        text: 'Tem certeza que deseja excluir essa foto?',
        toggle: true,
        onHide: () => this.modalOnHide(),
        confirm: 'Sim',
        onClickConfirm: async () => await _handleDelete(),
        danger: true,
        cancel: 'Não',
        onClickCancel: () => this.modalOnHide(),
      },
    });

    const _handleDelete = async () => {
      const {
        photo: { id },
      } = this.state;
      this.setState({ loadingState: true });

      const response = await photoService.admin.delete(id);

      if (response.ok && response.data) {
        const alertScreenState = {
          title: 'Foto excluída com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/admin/fotos',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }

      this.setState({ loadingState: false });
    };
  }

  body() {
    const { photo } = this.state;

    return (
      <>
        <Row align="center">
          {photo.files && photo.files.length ? (
            <Col>
              <Label text={'Foto carregada:'} />
              <Image
                preview
                img={
                  photo.files[0].id
                    ? `${baseURL()}api/file/${photo.files[0].id}/${photo.files[0].slug}`
                    : URL.createObjectURL(photo.files[0])
                }
              />
            </Col>
          ) : (
            <Col>
              <Label text={'Clique na imagem:'} />
              <ButtonUpload onChange={(e) => this.handleChange('files', e)} image />
            </Col>
          )}
        </Row>
        <Row align="center">
          <Col>
            {!this.state.newRegister && photo.files && photo.files.length ? (
              <>
                <Text title text={'Copiar URL do AWS S3'} />
                <p>{`https://biadola-photos.s3.amazonaws.com/${photo.files[0].urlS3}`}</p>
                <CopyToClipboard
                  text={`https://biadola-photos.s3.amazonaws.com/${photo.files[0].urlS3}`}
                  onCopy={() => this.setState({ copied: true })}>
                  <button>Copiar o link</button>
                </CopyToClipboard>
                <br />
                {this.state.copied ? <span style={{ color: 'red' }}>Copiado.</span> : null}
                <br />
                <br />
              </>
            ) : (
              <Text title text={'Adicionar foto'} />
            )}
          </Col>
        </Row>
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center">
          {this.state.photo.id ? (
            <Col>
              <Button danger text={'Excluir'} onClick={this.handleDelete} />
            </Col>
          ) : (
            <></>
          )}
          <Col>
            {!this.state.newRegister ? (
              <Button text={'Voltar'} onClick={() => this.props.history.goBack()} />
            ) : (
              <>
                <Button text={'Salvar'} onClick={this.handleSubmit} />
                <Button text={'Voltar'} onClick={() => this.props.history.goBack()} />
              </>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <>
        <Screen
          admin
          body={this.body}
          footer={this.footer}
          modalState={modalState}
          alertScreenState={alertScreenState}
          loadingState={loadingState}
        />
      </>
    );
  }
}

Photos.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Photos;
