import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Screen, Text, Input, TableAdmin } from '../../../components';
import { Row, Col } from 'react-bootstrap';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import photoService from '../../../services/photo';

const { defaultStates, defaultAlertScreenOnClick } = require('../../../utils/defaults');

class PhotosList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoList: [],
      ...defaultStates(),
    };

    this.modalOnHide = this.modalOnHide.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
    this.fillTable = this.fillTable.bind(this);
  }

  async componentDidMount() {
    await this.fillTable();
  }

  async fillTable(error = true) {
    this.setState({ loadingState: true });

    const response = await photoService.admin.getList();
    if (response.ok && response.data) {
      const data = response.data.map((i) => {
        return i;
      });
      this.setState({ photoList: data });
    } else {
      if (error) {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  modalOnHide() {
    const { modalState } = this.state;
    modalState.toggle = false;
    this.setState(modalState);
    this.fillTable(false);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  body() {
    return (
      <>
        <Row align={'center'}>
          <Col>
            <Text title text={'Fotos'} />
          </Col>
        </Row>
        <Row align="center">
          <Col>
            <Button
              underlinePlus
              text={'+ Adicionar'}
              onClick={() => this.props.history.push('/admin/fotos/novo')}
            />
          </Col>
        </Row>
        <Row align="center">
          <Col>
            <ImageList sx={{ width: '100%', height: '100%' }} cols={6} rowHeight={164}>
              {this.state.photoList.map((item) => (
                <ImageListItem key={item.id}>
                  <img
                    srcSet={`https://biadola-photos.s3.amazonaws.com/${item.file.urlS3}`}
                    src={`${item.img}`}
                    onClick={() => this.props.history.push(`/admin/fotos/${item.id}`)}
                    loading="lazy"
                    style={{ cursor: 'pointer' }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Col>
        </Row>
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center"></Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        admin
        body={this.body}
        footer={this.footer}
        modalState={modalState}
        loadingState={loadingState}
        alertScreenState={alertScreenState}
      />
    );
  }
}

PhotosList.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default PhotosList;
