import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'react-bootstrap';

import notificationService from '../../../services/notifications';

import { Screen } from '../../../components';

const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
  baseURL,
} = require('../../../utils/defaults');

class MlNotificationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: {},
      ...defaultStates(),
    };

    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
  }

  async componentDidMount() {
    this.setState({ loadingState: true });

    const { id } = this.props.match.params;

    const response = await notificationService.get(id);
    if (response.ok && response.data) {
      this.setState({
        notification: response.data,
      });
    }

    this.setState({ loadingState: false });
  }

  body() {
    const { notification } = this.state;

    return (
      <>
        <Row>
          <h3>Detalhes da notificação</h3>
          <Col lg={{ span: 6, order: 2 }} className={'align-items-center'}>
            <Container>
              <br />
              <h5>Tópico: Orders</h5>
              <br />
              <h5>Conteúdo:</h5>
              <h5>{notification.content}</h5>
            </Container>
          </Col>
        </Row>
      </>
    );
  }

  footer() {
    return <></>;
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <>
        <Screen
          admin
          body={this.body}
          footer={this.footer}
          modalState={modalState}
          alertScreenState={alertScreenState}
          loadingState={loadingState}
        />
      </>
    );
  }
}

MlNotificationDetails.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
  products: PropTypes.array,
};

export default MlNotificationDetails;
