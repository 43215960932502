import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SelectableCard from '../SelectableCard';

class SelectableTitleCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var { title, description, selected } = this.props;
    return (
      <SelectableCard onClick={this.props.onClick} selected={selected}>
        <div className="content">
          <h2 className="title">{title}</h2>
          <p className="description">{description}</p>
        </div>
      </SelectableCard>
    );
  }
}

SelectableTitleCard.propTypes = {
  //props
  title: PropTypes.string,
  description: PropTypes.string,
  selected: PropTypes.any,
  onClick: PropTypes.func,
};

export default SelectableTitleCard;
