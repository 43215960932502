const { dataFormParse } = require('../utils/dataFormParse');
const { axiosDefault, handleErrors } = require('../utils/defaults');

const announcementsService = {
  admin: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/announcements/admin/${id ? id : ''}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async (sellerId) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/announcements/list/${sellerId ? sellerId : ''}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getSearch: async ({ limit = '', search = '', descOrder = '' }) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(
          `/api/announcements/search/?limit=${limit}&title=${search}&descOrder=${descOrder}`,
        );

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getDetails: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/announcements/details/${id ? id : ''}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    edit: async (announcement) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.put(
          `/api/announcements/admin/${announcement.id}`,
          dataFormParse(announcement),
        );
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },
};

export default announcementsService;
