import React, { Component, useState } from 'react';
import PropTypes, { number } from 'prop-types';
import { Row, Col, Container } from 'react-bootstrap';
import announcementService from '../../../services/announcements';
import {
  Screen,
  Text,
  Image,
  Label,
  Input,
  CardAnnouncement,
  ListContainer,
  Button,
  Checkbox
} from '../../../components';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AnnouncementsOrdinationContainer, SelectOrder } from './styles';
import moment from 'moment';

const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
  baseURL,
} = require('../../../utils/defaults');

class Announcement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      announcement: {
        allowed_quantity: '',
        new_quantity: '',
        new_price: '',
        initial_price: '',
        reactivation: true
      },
      descOrder: true,
      limit: 8,
      ...defaultStates(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  async componentDidMount() {
    this.setState({ loadingState: true });

    const { id } = this.props.match.params;

    const resAnnouncement = await announcementService.admin.get(id);
    if (resAnnouncement.ok && resAnnouncement.data && resAnnouncement.data.announcements) {
      const formattedDate = moment(resAnnouncement.data.announcements.updatedAt).format('DD/MM/YYYY HH:mm:ss');

      resAnnouncement.data.announcements.initial_price = this.formatCurrency(resAnnouncement.data.announcements.initial_price);
      resAnnouncement.data.announcements.new_price = this.formatCurrency(resAnnouncement.data.announcements.new_price);
      resAnnouncement.data.announcements.updatedAt = formattedDate;
      this.setState({
        announcement: resAnnouncement.data.announcements,
      });
    }

    this.setState({ loadingState: false });
  }

  handleChange(id, event) {
    const { announcement } = this.state;

    if (id === 'allowed_quantity' || id === 'new_quantity') {
      const numericValue = event.target.value.replace(/[^0-9]/g, '');
      announcement[id] = numericValue;
    }

    if (id === 'new_price' || id === 'initial_price') {
      announcement[id] = this.formatCurrency(event.target.value)
    }

    this.setState({ announcement });
  }

  formatCurrency(value) {
    let numberValue;

    if (typeof value === 'number') {
      value = String(value);
      numberValue = parseFloat(value);
    } else {
      value = value?.trim();

      if (value === 'R$' || !value) {
        return 'R$ 0,00';
      }

      numberValue = parseFloat(value.replace(/[^0-9]/g, '')) / 100;
    }

    return numberValue.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  unformatCurrency(value) {
    if (typeof value === 'string') {

      const money = parseFloat(value.replace(/[^\d,-]/g, '').replace(',', '.'))

      if (money === 0) {
        return null;
      } else {
        return money;
      }
    }

    return null;
  }

  async handleSubmit() {
    const { announcement } = this.state;

    announcement.initial_price = this.unformatCurrency(announcement.initial_price); 
    announcement.new_price = this.unformatCurrency(announcement.new_price); 

    this.setState({ loadingState: true });

    const response = await announcementService.admin.edit(announcement);

    if (response.ok) {
      const alertScreenState = {
        title: 'Anúncio editado com sucesso!',
        open: true,
        onClick: this.alertScreenOnClick,
        pagePath: '/admin/anuncios',
      };

      this.setState({ alertScreenState });
    } else {
      const modalState = {
        title: 'Erro',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };

      this.setState({ modalState });
    }

    this.setState({ loadingState: false });
  }

  handleCheckboxClick = () => {
    this.setState((prevState) => ({
      announcement: {
        ...prevState.announcement,
        reactivation: !prevState.announcement.reactivation,
      },
    }));
  };

  body() {
    const { announcement } = this.state;

    return (
      <>
        <Row align="center">
          <Col>
            <Text title text={'Anúncio'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Checkbox
              label={'Reativar anúncio'}
              checked={announcement.reactivation}
              switchButton
              onClick={this.handleCheckboxClick}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} xs={6}>
            <Input
              value={announcement.allowed_quantity}
              label={'Quantidade de estoque permitida:'}
              placeholder={'Quantidade'}
              maxLength={200}
              onChange={(e) => this.handleChange('allowed_quantity', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} xs={6}>
            <Input
              value={announcement.new_quantity}
              label={'Nova quantidade de estoque:'}
              placeholder={'Quantidade'}
              maxLength={200}
              onChange={(e) => this.handleChange('new_quantity', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} xs={6}>
            <Input
              value={announcement.initial_price}
              label={'Valor inicial do estoque:'}
              placeholder={'Valor'}
              maxLength={30}
              onChange={(e) => this.handleChange('initial_price', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} xs={6}>
            <Input
              value={announcement.new_price}
              label={'Novo valor do estoque:'}
              placeholder={'Valor'}
              maxLength={30}
              onChange={(e) => this.handleChange('new_price', e)}
            />
          </Col>
        </Row>
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center" className="mt-5">
          <Col>
            <Button text={'Salvar'} onClick={this.handleSubmit} />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <>
        <Screen
          admin
          body={this.body}
          footer={this.footer}
          modalState={modalState}
          alertScreenState={alertScreenState}
          loadingState={loadingState}
        />
      </>
    );
  }
}

Announcement.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Announcement;
