import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../../utils/devices';

export const SelectOrder = styled.div`
  fieldset {
    display: none;
  }

  .MuiOutlinedInput-root {
    & div {
      padding: 0 32px !important;
    }
  }

  .MuiSelect-select {
    opacity: 1;
    border: none;
    color: ${colors.secondaryTwo};

    @media ${bootstrapMax.md} {
      font-size: 12px;
    }
  }
`;

export const AnnouncementsOrdinationContainer = styled.div`
  @media ${bootstrapMax.sm} {
    margin-right: -15px;
  }

  @media (max-width: 374px) {
    margin-top: 16px;
  }

  @media (min-width: 375px) {
    position: absolute;
    margin-top: -44px;
  }

  @media (min-width: 577px) {
    margin-top: -70px;
  }

  @media (min-width: 992px) {
    position: initial;
    display: flex;
    margin-top: -5px;
  }
`;
