import React, { useContext } from 'react';
import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Context } from '../../context/authContext';
import { CustomModal } from '../../components/Loading/styles';
import { NavbarAdminApp } from '../../components';
import { bootstrapMax } from '../../utils/devices';

const Div = styled.div`
  min-height: 80vh;

  @media ${bootstrapMax.md} {
    padding-top: 53px;
  }
`;

const CustomRoute = ({ isPrivate, ...rest }) => {
  const { auth, loading, checkLogin } = useContext(Context);
  let history = useHistory();

  if (loading) return <CustomModal />;
  if (isPrivate) checkLogin();
  if (isPrivate && !auth) return (window.location.href = '/admin/login');

  const showAdminNavbar = () =>
    auth && !window.location.pathname.includes('login') && rest.path.includes('/admin');

  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;

  return (
    <>
      {showAdminNavbar() && <NavbarAdminApp />}
      <Div>
        <Route exact={rest.exact} path={rest.path} component={rest.component} history={history} />
      </Div>
    </>
  );
};

CustomRoute.propTypes = { isPrivate: PropTypes.bool };

export default CustomRoute;
