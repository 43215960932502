import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  CloseButton,
  DownloadButton,
  SearchButton,
  DownloadButtonApp,
  SearchAdmin,
  SearchIcon,
  SearchIconAdmin,
  Simple,
  BackText,
  LinkButton,
  BackButton,
  UpButton,
  GreenButton,
  GreenButtonSearch,
} from './styles';

import close_maskavo from './../../assets/icons/admin/close_maskavo-min.png';
import search_icon from './../../assets/icons/admin/search_white@2x-min.png';
import download_white from './../../assets/icons/admin/download_white@2x-min.png';
import arrow_left from './../../assets/icons/admin/arrow-left.png';

class Button extends Component {
  render() {
    // props
    const { text, onClick, to } = this.props;

    //types
    const {
      back,
      backApp,
      close,
      search,
      downloadApp,
      searchAdmin,
      danger,
      up,
      greenButton,
      greenButtonSearch,
      width,
      widthMobile,
      height,
      success,
      error,
    } = this.props;

    if (greenButton) {
      return (
        <GreenButton
          className={'green-button'}
          onClick={onClick}
          width={width}
          widthMobile={widthMobile}
          height={height}
          success={success}
          error={error}>
          {text}
        </GreenButton>
      );
    }

    if (greenButtonSearch) {
      return (
        <GreenButtonSearch onClick={onClick} width={width} widthMobile={widthMobile}>
          {text}
        </GreenButtonSearch>
      );
    }

    if (back)
      return (
        <LinkButton onClick={onClick}>
          <img width={23} src={arrow_left} />
          <BackText>Voltar</BackText>
        </LinkButton>
      );

    if (backApp)
      return (
        <BackButton to={to} onClick={onClick}>
          <img width={23} src={arrow_left} />
        </BackButton>
      );

    if (close)
      return (
        <CloseButton onClick={onClick}>
          <div>
            <img src={close_maskavo} />
          </div>
        </CloseButton>
      );

    if (downloadApp)
      return (
        <DownloadButton onClick={onClick}>
          <DownloadButtonApp src={download_white} />
        </DownloadButton>
      );

    if (search)
      return (
        <SearchButton onClick={onClick}>
          <SearchIcon src={search_icon} />
        </SearchButton>
      );

    if (searchAdmin)
      return (
        <SearchAdmin onClick={onClick}>
          <SearchIconAdmin src={search_icon} />
          {text}
        </SearchAdmin>
      );

    if (up) {
      return <UpButton onClick={onClick} />;
    }

    return (
      <Simple danger={danger} onClick={onClick}>
        {text}
      </Simple>
    );
  }
}

Button.propTypes = {
  // props
  text: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.number,
  widthMobile: PropTypes.number,
  height: PropTypes.number,

  // types
  back: PropTypes.bool,
  backApp: PropTypes.bool,
  close: PropTypes.bool,
  search: PropTypes.bool,
  downloadApp: PropTypes.bool,
  searchAdmin: PropTypes.bool,
  danger: PropTypes.bool,
  up: PropTypes.bool,
  greenButton: PropTypes.bool,
  greenButtonSearch: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
};

export default Button;
