import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItem from '../ListItem';
import { ListContent } from './style';

class ListContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // props
    const { permalink, secure_thumbnail, title, price, seller_reputation, products, history } =
      this.props;

    return (
      <ListContent>
        {products.map((product) => {
          return (
            <ListItem
              key={product.id_ml}
              id_ml={product.id_ml}
              title={product.title}
              secure_thumbnail={product.secure_thumbnail}
              price={product.price}
              permalink={product.permalink}
              seller_reputation={product.seller_reputation}
              history={history}
            />
          );
        })}
      </ListContent>
    );
  }
}

ListContainer.propTypes = {
  //props
  products: PropTypes.array,
  permalink: PropTypes.string,
  title: PropTypes.string,
  secure_thumbnail: PropTypes.string,
  price: PropTypes.string,
  seller_reputation: PropTypes.string,
  history: PropTypes.object,
};

export default ListContainer;
