import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const ItemContainer = styled.div`
  border-radius: 4px;
  background-color: #fff;
  height: 150px;
  width: 350px;
  color: #29303b;
  margin-bottom: 10px;
  margin-right: 12px;
  padding: 10px;
  cursor: pointer;
`;

export const Thumbnail = styled.img`
  width: auto;
  height: 100%;
  border: 0;
  vertical-align: middle;
  float: left;
  margin-right: 10px;
`;

export const TitlePane = styled.div`
  font-weight: 700;
  margin-bottom: 5px;
`;

export const PricePane = styled.div`
  margin-bottom: 5px;
`;

export const ItemLink = styled.a`
  text-decoration: none;
`;

export const TagDate = styled.span`
  ${({ category }) =>
    category === 'silver' &&
    `
    background: ${colors.silver};
    color: ${colors.white};
  `}
  ${({ category }) =>
    category === 'gold' &&
    `
    background: ${colors.gold};
    color: ${colors.primaryOne};
  `}
  ${({ category }) =>
    category === 'platinum' &&
    `
    background: ${colors.platinum};
    color: ${colors.primaryOne};
  `}
  font-family: 'Trade Gothic LT';
  display: inline-block;
  font-weight: bold;
  text-align: center;
  max-width: 53px;
  min-height: 19px;
  min-width: 76px;
  font-size: 12px;
  padding-top: 2px;
`;
