import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Label } from '../index';
import {
  Nav,
  StyledBurger,
  Ul,
  ScrollList,
  Logo,
  LogoMenu,
  Close,
  MenuItem,
  MenuHeader,
  Header,
} from './styles';
import { NavLink } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { defaultStates } from '../../utils/defaults';

import hamburger from '../../assets/icons/menu/admin/menu-icon.png';
import logo from '../../assets/logos/Admin@2x.png';
import logoWhite from '../../assets/logos/Admin.png';
import close from '../../assets/icons/mobile/close-mobile.png';

class NavbarAdminApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbar: false,
      alerts: null,
      ...defaultStates(),
    };
  }

  componentDidMount() {}

  render() {
    const { back } = this.props;

    return (
      <>
        <Nav>
          <Header className={'header'}>
            <Row className={'navbar-expand-lg align-items-center'}>
              <Col
                lg={{ span: 2, order: 1 }}
                className={
                  'd-flex justify-content-center justify-content-lg-start justify-content-xl-start'
                }>
                <Logo img={logo} href={'/admin'}>
                  <div />
                </Logo>
              </Col>

              <Col
                lg={{ span: 1, order: 2 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/admin'}>
                  <Label header text={'Home'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 2, order: 2 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/admin/anuncios'}>
                  <Label header text={'Anúncios'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 2, order: 2 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/admin/debitos'}>
                  <Label header text={'Débitos'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 2, order: 2 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/admin/notifications'}>
                  <Label header text={'Notificações ML'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 1, order: 2 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/admin/fotos'}>
                  <Label header text={'Fotos'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 1, order: 5 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/admin/logout'}>
                  <Label header text={'Sair'} />
                </NavLink>
              </Col>

              <Col
                xs={{ span: 1, order: 1 }}
                sm={{ span: 1, order: 1 }}
                lg={{ span: 1, offset: 0, order: 3 }}
                className={`d-${
                  back ? 'none' : 'flex'
                } d-sm-flex justify-content-start justify-content-lg-end justify-content-xl-end`}>
                <StyledBurger
                  className="d-flex d-xl-none d-lg-none"
                  open={this.state.navbar}
                  img={hamburger}
                  onClick={() => {
                    this.setState({ navbar: !this.state.navbar });
                  }}>
                  <div />
                </StyledBurger>
              </Col>
            </Row>
          </Header>
        </Nav>

        <Ul open={this.state.navbar}>
          <MenuHeader key={0} className="d-flex align-items-center justify-content-between">
            <LogoMenu src={logoWhite} alt="fgcoop logotipo" />
            <Close img={close} onClick={() => this.setState({ navbar: false })} />
          </MenuHeader>
          <ScrollList>
            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={1}
              to="/admin">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Home'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={2}
              to="/admin/anuncios">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Anúncios'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={2}
              to="/admin/notifications">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Notificações ML'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={3}
              to="/admin/fotos">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Fotos'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={5}
              to="/admin/logout">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Sair'} />
              </MenuItem>
            </NavLink>
          </ScrollList>
        </Ul>
      </>
    );
  }
}

NavbarAdminApp.propTypes = { back: PropTypes.bool };

export default NavbarAdminApp;
