import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import { Text } from '..';
import logo from '../../assets/imgs/mercado-livre.png';
import { Logo } from './styles';

class CardAnnouncement extends Component {
  render() {
    // props
    const {
      id,
      title,
      id_ml,
      price,
      available_quantity,
      listing_type_id,
      permalink,
      secure_thumbnail,
      shipping_mode,
      history,
    } = this.props;

    //types
    const { catalog } = this.props;

    if (catalog) {
      return (
        <>
          <Card sx={{ maxWidth: 345 }}>
            <CardMedia component="img" alt="green iguana" height="140" image={secure_thumbnail} />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {title}
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                R$ {price}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                ID Mercado Livre: {id_ml}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Quantidade: {available_quantity}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Tipo de Anúncio: {listing_type_id}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Frete: {shipping_mode}
              </Typography>
              <br />
              <Logo img={logo} href={permalink} target={'_blank'}>
                <div />
              </Logo>
            </CardContent>
            <CardActions>
              <Button onClick={() => history.goBack()} size="small">
                Voltar
              </Button>
            </CardActions>
          </Card>
        </>
      );
    }

    return (
      <>
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia component="img" alt="green iguana" height="140" image={secure_thumbnail} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography gutterBottom variant="h5" component="div">
              R$ {price}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              ID Mercado Livre: {id_ml}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Quantidade: {available_quantity}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Tipo de Anúncio: {listing_type_id}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Frete: {shipping_mode}
            </Typography>
            <br />
            <Logo img={logo} href={permalink} target={'_blank'}>
              <div />
            </Logo>
          </CardContent>
          <CardActions>
            <Button href="/admin/anuncios" size="small">
              Voltar
            </Button>
            <Button onClick={() => history.push('/admin/anuncio/editar/' + id)} size="small">
              Editar
            </Button>
          </CardActions>
        </Card>
      </>
    );
  }
}

CardAnnouncement.propTypes = {
  //props
  id: PropTypes.number,
  title: PropTypes.string,
  id_ml: PropTypes.string,
  price: PropTypes.number,
  available_quantity: PropTypes.number,
  listing_type_id: PropTypes.string,
  permalink: PropTypes.string,
  secure_thumbnail: PropTypes.string,
  shipping_mode: PropTypes.string,
  history: PropTypes.object,

  //types
  catalog: PropTypes.bool,
};

export default CardAnnouncement;
