import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ItemContainer, Thumbnail, TitlePane, PricePane, ItemLink, Logo, TagDate } from './style';
import logo from '../../assets/imgs/mercado-livre.png';

class ListItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // props
    const { id_ml, permalink, secure_thumbnail, info, title, price, seller_reputation, history } =
      this.props;

    return (
      <ItemLink title={title} onClick={() => history.push('/admin/anuncio/detalhes/' + id_ml)}>
        <ItemContainer>
          <Thumbnail src={secure_thumbnail} />
          <TitlePane>{title.substring(0, 45) + ''}</TitlePane>
          <PricePane>R$ {price}</PricePane>
          {seller_reputation ? (
            <TagDate category={seller_reputation}>{seller_reputation}</TagDate>
          ) : null}
        </ItemContainer>
      </ItemLink>
    );
  }
}

ListItem.propTypes = {
  //props
  id_ml: PropTypes.string,
  permalink: PropTypes.string,
  title: PropTypes.string,
  secure_thumbnail: PropTypes.string,
  price: PropTypes.number,
  history: PropTypes.object,
  seller_reputation: PropTypes.string,

  // types
  info: PropTypes.bool,
};

export default ListItem;
