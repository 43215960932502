import styled from 'styled-components';

import left_bracket from '../../../assets/icons/admin/left-bracket.png';

export const LeftBracketIcon = styled.span`
  background-image: url(${left_bracket});
  background-size: 17px 82px;
  background-repeat: no-repeat;
  height: 82px;
  padding: 17px;
`;
