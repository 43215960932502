import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import CustomRoute from './CustomRoute';
import { AuthProvider } from '../../context/authContext';

import AdminLogin from '../admin/Login';
import AdminLogout from '../admin/Logout';
import AdminHome from '../admin/Home';
import Error from '../admin/Error';

import AdminAnnouncements from '../admin/Announcements';
import AdminAnnouncementsList from '../admin/Announcements/list';
import AdminAnnouncementEdit from '../admin/Announcements/edit';
import AdminAnnouncementDetails from '../admin/Announcements/details';
import AdminPhotos from '../admin/Photos';
import AdminPhotosList from '../admin/Photos/list';

import MlNotificationsList from '../admin/MlNotifications/list';
import MlNotificationDetails from '../admin/MlNotifications';

import AdminDebitsMlList from '../admin/DebitsMl/list';

class Router extends Component {
  render() {
    return (
      <AuthProvider>
        <Switch>
          <CustomRoute exact path="/" component={AdminLogin} />

          <CustomRoute exact path="/admin/login" component={AdminLogin} />
          <CustomRoute exact path="/admin/logout" component={AdminLogout} />
          <CustomRoute isPrivate exact path="/admin" component={AdminHome} />

          <CustomRoute isPrivate exact path="/admin/anuncios" component={AdminAnnouncementsList} />
          <CustomRoute isPrivate exact path="/admin/anuncio/:id" component={AdminAnnouncements} />
          <CustomRoute exact path="/admin/anuncio/editar/:id" component={AdminAnnouncementEdit} />
          <CustomRoute exact path="/admin/anuncio/detalhes/:id" component={AdminAnnouncementDetails} />

          <CustomRoute exact path="/admin/notifications" component={MlNotificationsList} />
          <CustomRoute exact path="/admin/notifications/:id" component={MlNotificationDetails} />

          <CustomRoute isPrivate exact path="/admin/fotos" component={AdminPhotosList} />
          <CustomRoute isPrivate exact path="/admin/fotos/:id" component={AdminPhotos} />

          <CustomRoute isPrivate exact path="/admin/debitos" component={AdminDebitsMlList} />

          <CustomRoute path="/*" component={Error} />
        </Switch>
      </AuthProvider>
    );
  }
}

export default Router;
