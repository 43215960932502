import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '../Card';

class SelectableCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var isSelected = this.props.selected ? 'selected' : '';
    var className = 'selectable ' + isSelected;
    return (
      <Card>
        <div className={className} onClick={this.props.onClick}>
          {this.props.children}
          <div className="check">
            <span className="checkmark">✔</span>
          </div>
        </div>
      </Card>
    );
  }
}

SelectableCard.propTypes = {
  //props
  selected: PropTypes.any,
  children: PropTypes.object,
  onClick: PropTypes.func,
};

export default SelectableCard;
