import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

import {
  Text as TextComponent,
  TextLink,
  TitleApp,
  TitleApp2,
  TitleApp3,
  TitleHeading,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Label,
  Paragraph1,
  Paragraph2,
  Paragraph3,
  LabelAccordion,
  Paragraph4,
  TitlePlatformCard,
  LabelContact,
  TitleWhite,
  LabelHome,
  TitleSearch,
  ModalTitle,
  ModalContent,
  TextMenu,
  ProductsOrdination,
  Message,
  Paragraph5,
} from './styles';

class Text extends Component {
  render() {
    // props
    const {
      title,
      text,
      href,
      searched,
      pre,
      textAlign,
      textAlignMobile,
      lineHeight,
      fontSize,
      fontSizeMobile,
      className,
    } = this.props;

    //types
    const {
      link,
      titleApp,
      titleApp2,
      titleApp3,
      linkApp,
      menu,
      titleHeadApp,
      titleSearch,
      heading1,
      heading2,
      heading3,
      heading4,
      heading5,
      paragraph1,
      paragraph2,
      paragraph2center,
      paragraph3,
      paragraph4,
      paragraph5,
      footer,
      label,
      labelAcordeon,
      labelAcordeonMini,
      labelAcordeonFaq,
      contact,
      titlePlatformCard,
      titleWhite,
      home,
      labelHome,
      modalTitle,
      modalContent,
      lightColor,
      noWrap,
      center,
      borderLeft,
      productsOrdination,
      lightFont,
      messageSuccess,
      doc,
    } = this.props;

    if (messageSuccess) {
      return <Message className={className}>{text}</Message>;
    }

    if (titleHeadApp) {
      return <TitleHeading className={`bold ${className}`}>{text}</TitleHeading>;
    }

    if (titleSearch) {
      return (
        <TitleSearch className={`bold ${className}`}>
          <span>{text}</span>
          {`"${searched}"`}
        </TitleSearch>
      );
    }

    if (heading1) {
      return (
        <Heading1
          home={home}
          lightColor={lightColor}
          noWrap={noWrap}
          textAlign={textAlign}
          lineHeight={lineHeight}>
          <h1 className={`bold ${className}`}>{title.one}</h1>
          <h1>{title.two}</h1>
        </Heading1>
      );
    }

    if (heading2) {
      return (
        <Heading2 textAlign={textAlign} lightColor={lightColor} noWrap={noWrap}>
          <h2>{title.one}</h2>
          <h2 className={`bold ${className}`}>{title.two}</h2>
        </Heading2>
      );
    }

    if (heading3) {
      return (
        <Heading3 oneLine={!title.two} textAlign={textAlign}>
          <h1>{title.one}</h1>
          <h1 className={`bold ${className}`}>{title.two}</h1>
        </Heading3>
      );
    }

    if (heading4) {
      return (
        <Heading4
          className={className}
          textAlign={textAlign}
          lightColor={lightColor}
          noWrap={noWrap}>
          <h4>{title}</h4>
        </Heading4>
      );
    }

    if (heading5) {
      return (
        <Heading5
          textAlign={textAlign}
          className={className}
          lightColor={lightColor}
          noWrap={noWrap}>
          <h5>{title}</h5>
        </Heading5>
      );
    }

    if (titleApp) return <TitleApp className={`bold ${className}`}>{text}</TitleApp>;

    if (titleApp2) return <TitleApp2 className={`bold ${className}`}>{text}</TitleApp2>;

    if (titleApp3) return <TitleApp3 className={`bold ${className}`}>{text}</TitleApp3>;

    if (paragraph1)
      return (
        <Paragraph1
          lightColor={lightColor}
          lightFont={lightFont}
          center={center}
          className={className}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
        />
      );

    if (paragraph2)
      return (
        <Paragraph2
          className={className}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
        />
      );

    if (paragraph2center)
      return (
        <Paragraph2
          className={className}
          center
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
        />
      );

    if (paragraph3)
      return (
        <Paragraph3
          className={className}
          center={center}
          pre={pre}
          lightColor={lightColor}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
        />
      );

    if (paragraph4)
      return (
        <Paragraph4
          className={className}
          borderLeft={borderLeft}
          lightColor={lightColor}
          lineHeight={lineHeight}
          fontSizeMobile={fontSizeMobile}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
        />
      );

    if (paragraph5) {
      return (
        <Paragraph5
          className={className}
          lightColor={lightColor}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
        />
      );
    }

    if (productsOrdination)
      return (
        <ProductsOrdination
          className={className}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
        />
      );

    if (menu) {
      return <TextMenu className={className}>{text}</TextMenu>;
    }

    if (linkApp) {
      return (
        <TextLink className={className} footer={footer} app href={href} target="_blank">
          {text}
        </TextLink>
      );
    }

    if (link)
      return (
        <TextLink
          className={className}
          footer={footer}
          href={href}
          fontSize={fontSize}
          target="_blank">
          {text}
        </TextLink>
      );

    if (label) return <Label className={`bold ${className}`}>{text}</Label>;
    if (labelHome) return <LabelHome className={className}>{text}</LabelHome>;

    if (labelAcordeon)
      return <LabelAccordion className={`bold ${className}`}>{text}</LabelAccordion>;

    if (labelAcordeonMini)
      return (
        <LabelAccordion mini className={`bold ${className}`}>
          {text}
        </LabelAccordion>
      );

    if (labelAcordeonFaq)
      return (
        <LabelAccordion className={className} faq>
          {text}
        </LabelAccordion>
      );

    if (titlePlatformCard)
      return <TitlePlatformCard className={`bold ${className}`}>{text}</TitlePlatformCard>;

    if (contact) return <LabelContact className={className}>{text}</LabelContact>;

    if (titleWhite)
      return (
        <TitleWhite
          fontSizeMobile={fontSizeMobile}
          textAlignMobile={textAlignMobile}
          className={`bold ${className}`}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
        />
      );
    if (modalTitle) {
      const newText = text && text.split(' ');
      if (newText && newText.length > 1) {
        const one = `${newText[0]} ${newText.length > 2 ? newText[1] : ''}`;
        const two = `${newText.length === 2 ? newText[1] : newText[2]}`;
        return (
          <Heading2 className={className}>
            <h1>{one}</h1>
            <h1 className={'bold'}>{two}</h1>
          </Heading2>
        );
      }
      return (
        <ModalTitle
          className={`bold ${className}`}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
        />
      );
    }
    if (modalContent) {
      return (
        <ModalContent
          className={`mt-4 text-left ${className}`}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
        />
      );
    }

    return (
      <TextComponent className={className} home={home} doc={doc}>
        {text}
      </TextComponent>
    );
  }
}

Text.propTypes = {
  // props
  text: PropTypes.string,
  href: PropTypes.string,
  textAlign: PropTypes.string,
  searched: PropTypes.string,
  textAlignMobile: PropTypes.string,
  title: PropTypes.any,
  pre: PropTypes.bool,
  lineHeight: PropTypes.number,
  fontSize: PropTypes.number,
  fontSizeMobile: PropTypes.number,
  className: PropTypes.string,

  // types
  menu: PropTypes.bool,
  footer: PropTypes.bool,
  heading1: PropTypes.bool,
  heading2: PropTypes.bool,
  heading3: PropTypes.bool,
  heading4: PropTypes.bool,
  heading5: PropTypes.bool,
  paragraph1: PropTypes.bool,
  paragraph2: PropTypes.bool,
  paragraph2center: PropTypes.bool,
  paragraph3: PropTypes.bool,
  paragraph4: PropTypes.bool,
  productsOrdination: PropTypes.bool,
  paragraph5: PropTypes.bool,
  link: PropTypes.bool,
  titleApp: PropTypes.bool,
  titleApp2: PropTypes.bool,
  titleApp3: PropTypes.bool,
  linkApp: PropTypes.bool,
  titleHeadApp: PropTypes.bool,
  titleSearch: PropTypes.bool,
  label: PropTypes.bool,
  labelHome: PropTypes.bool,
  labelAcordeon: PropTypes.bool,
  labelAcordeonMini: PropTypes.bool,
  labelAcordeonFaq: PropTypes.bool,
  titlePlatformCard: PropTypes.bool,
  contact: PropTypes.bool,
  titleWhite: PropTypes.bool,
  home: PropTypes.bool,
  modalTitle: PropTypes.bool,
  modalContent: PropTypes.bool,
  lightColor: PropTypes.bool,
  noWrap: PropTypes.bool,
  center: PropTypes.bool,
  borderLeft: PropTypes.bool,
  lightFont: PropTypes.bool,
  messageSuccess: PropTypes.bool,
  doc: PropTypes.bool,
};

export default Text;
