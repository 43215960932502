import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Screen, Text, CardAccountant } from '../../../components';

import sellerService from '../../../services/seller';

const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
} = require('../../../utils/defaults');

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      ...defaultStates(),
    };

    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  async componentDidMount() {
    await this.fillSellers();
    this.setState({ loadingState: true });
    this.setState({ loadingState: false });
  }

  async fillSellers(error = true) {
    this.setState({ loadingState: true });

    const response = await sellerService.admin.getList();
    if (response.ok && response.data) {
      const data = response.data;
      data.push({ id: 0, id_ml: '0000000000', nickname: 'Todas', email: 'todas' });
      this.setState({ options: data });
    } else {
      if (error) {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  body() {
    return (
      <>
        <Row align="center">
          <Col>
            <Text title text={'Administrativo | Mercado Livre'} />
          </Col>
        </Row>
        <Row align="center">
          <Col>
            <CardAccountant title="Conta vendedor" cardContents={this.state.options} />
          </Col>
        </Row>
      </>
    );
  }

  footer() {
    return <></>;
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <>
        <Screen
          admin
          body={this.body}
          footer={this.footer}
          modalState={modalState}
          alertScreenState={alertScreenState}
          loadingState={loadingState}
        />
      </>
    );
  }
}

Home.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Home;
