import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Screen, Text, Input, TableAdmin, Label } from '../../../components';
import { Row, Col, Form } from 'react-bootstrap';
import MUIDataTable from 'mui-datatables';

import productService from '../../../services/announcements';
import sellerService from '../../../services/seller';

const { defaultStates, defaultAlertScreenOnClick } = require('../../../utils/defaults');

class ProductsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerML: '',
      options: [],
      productsList: [],
      ...defaultStates(),
    };

    this.modalOnHide = this.modalOnHide.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
    this.fillTable = this.fillTable.bind(this);
    this.fillSellers = this.fillSellers.bind(this);
  }

  async componentDidMount() {
    await this.fillSellers();
    const seller = localStorage.getItem('sellerML');
    if (seller !== 'null') {
      this.setState({ sellerML: seller });
    }
    await this.fillTable(this.state.sellerML);
  }

  handleChange(id, event) {
    if (id === 'seller') {
      this.setState({ sellerML: event.target.value });
      this.fillTable(event.target.value);
      localStorage.setItem('sellerML', event.target.value);
    }
  }

  async fillTable(sellerML, error = true) {
    this.setState({ loadingState: true });

    const response = await productService.admin.getList(sellerML);
    if (response.ok && response.data) {
      const data = response.data;
      this.setState({ productsList: data });
    } else {
      if (error) {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  async fillSellers(error = true) {
    this.setState({ loadingState: true });

    const response = await sellerService.admin.getList();
    if (response.ok && response.data) {
      const data = response.data;
      this.setState({ sellerML: data[0].id_ml });
      this.setState({ options: data });
    } else {
      if (error) {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  modalOnHide() {
    const { modalState } = this.state;
    modalState.toggle = false;
    this.setState(modalState);
    this.fillTable(false, this.state.sellerML);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  body() {
    const onCellClick = ({ id }) => this.props.history.push(`/admin/anuncio/${id}`);
    const { sellerML, options } = this.state;

    const columns = [
      {
        name: 'label',
        label: 'Descrição',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'accountant',
        label: 'Conta',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'amount',
        label: 'Valor',
        options: {
          sort: true,
        },
      },
    ];

    const data = [
      {
        label: 'Estorno do custo de envio externo ou inter municipal',
        amount: 338.75,
        accountant: 'BTNEWTINTAS',
      },
      { label: 'Estorno do custo de gestão da venda', amount: 280.92, accountant: 'BTNEWTINTAS' },
      { label: 'Estorno da tarifa de venda', amount: 1197.67, accountant: 'BTNEWTINTAS' },
      {
        label: 'Bonificação campanhas de publicidade - Product Ads',
        amount: 6.96,
        accountant: 'BTNEWTINTAS',
      },
      {
        label: 'Estorno do custo de envio externo ou inter municipal',
        amount: 327.46,
        accountant: 'BTNEWTINTAS',
      },
      {
        label: 'Campanhas de publicidade - Product Ads',
        amount: 3955.38,
        accountant: 'BTNEWTINTAS',
      },
      { label: 'Tarifa de envio interno à cidade', amount: 12.07, accountant: 'BTNEWTINTAS' },
      {
        label: 'Tarifa de devolução por envio externo ou intermunicipal',
        amount: 114.8,
        accountant: 'BTNEWTINTAS',
      },
      {
        label: 'Tarifa de envio extra ou intermunicipal',
        amount: 13325.04,
        accountant: 'BTNEWTINTAS',
      },
      { label: 'Tarifa pelo serviço de armazenamento', amount: 125.98, accountant: 'BTNEWTINTAS' },
      { label: 'Tarifa pelo serviço de armazenamento', amount: 136.42, accountant: 'BTNEWTINTAS' },
      { label: 'Tarifa pelo serviço de armazenamento', amount: 22.89, accountant: 'BTNEWTINTAS' },
      { label: 'Tarifa pelo serviço de armazenamento', amount: 15.42, accountant: 'BTNEWTINTAS' },
      { label: 'Custo de gestão da venda', amount: 7543.94, accountant: 'BTNEWTINTAS' },
      { label: 'Tarifa de venda', amount: 34862.1, accountant: 'BTNEWTINTAS' },
      { label: 'Tarifa de envio interno à cidade', amount: 29.52, accountant: 'BTNEWTINTAS' },
      {
        label: 'Tarifa de envio extra ou intermunicipal',
        amount: 7569.97,
        accountant: 'BTNEWTINTAS',
      },
      { label: 'Custo do serviço de coleta Full', amount: 151.95, accountant: 'BTNEWTINTAS' },
      { label: 'Tarifa pela devolução', amount: 48, accountant: 'BTNEWTINTAS' },
      {
        label: 'Estorno do custo de envio externo ou inter municipal',
        amount: 75.37,
        accountant: 'BT NEW',
      },
      { label: 'Estorno do custo de gestão da venda', amount: 11.24, accountant: 'BT NEW' },
      { label: 'Estorno da tarifa de venda', amount: 44.91, accountant: 'BT NEW' },
      { label: 'Campanhas de publicidade - Product Ads', amount: 649.78, accountant: 'BT NEW' },
      { label: 'Tarifa de envio extra ou intermunicipal', amount: 3450.49, accountant: 'BT NEW' },
      { label: 'Custo de gestão da venda', amount: 974.3, accountant: 'BT NEW' },
      { label: 'Tarifa de venda', amount: 2788.24, accountant: 'BT NEW' },
      { label: 'Tarifa pela devolução', amount: 14.7, accountant: 'BT NEW' },
      {
        label: 'Estorno do custo de envio externo ou inter municipal',
        amount: 338.75,
        accountant: 'BT NEW',
      },
      { label: 'Estorno do custo de gestão da venda', amount: 280.92, accountant: 'BT NEW' },
      { label: 'Estorno da tarifa de venda', amount: 1197.67, accountant: 'BT NEW' },
      {
        label: 'Bonificação campanhas de publicidade - Product Ads',
        amount: 6.96,
        accountant: 'BT NEW',
      },
      {
        label: 'Estorno do custo de envio externo ou inter municipal',
        amount: 327.46,
        accountant: 'BT NEW',
      },
      { label: 'Campanhas de publicidade - Product Ads', amount: 3955.38, accountant: 'BT NEW' },
      { label: 'Tarifa de envio interno à cidade', amount: 12.07, accountant: 'BT NEW' },
      {
        label: 'Tarifa de devolução por envio externo ou intermunicipal',
        amount: 114.8,
        accountant: 'BT NEW',
      },
      {
        label: 'Tarifa de envio extra ou intermunicipal',
        amount: 13325.04,
        accountant: 'BT NEW',
      },
      { label: 'Tarifa pelo serviço de armazenamento', amount: 125.98, accountant: 'BT NEW' },
      { label: 'Tarifa pelo serviço de armazenamento', amount: 136.42, accountant: 'BT NEW' },
      { label: 'Tarifa pelo serviço de armazenamento', amount: 22.89, accountant: 'BT NEW' },
      { label: 'Tarifa pelo serviço de armazenamento', amount: 15.42, accountant: 'BT NEW' },
      { label: 'Custo de gestão da venda', amount: 7543.94, accountant: 'BT NEW' },
      { label: 'Tarifa de venda', amount: 34862.1, accountant: 'BT NEW' },
      { label: 'Tarifa de envio interno à cidade', amount: 29.52, accountant: 'BT NEW' },
      { label: 'Tarifa de envio extra ou intermunicipal', amount: 7569.97, accountant: 'BT NEW' },
      { label: 'Custo do serviço de coleta Full', amount: 151.95, accountant: 'BT NEW' },
      { label: 'Tarifa pela devolução', amount: 48, accountant: 'BT NEW' },
    ];

    const optionsMui = {
      filterType: 'checkbox',
      selectableRows: 'none',
      responsive: 'standard',
      print: false,
    };

    return (
      <>
        <Row align={'center'}>
          <Col>
            <Text title text={'Financeiro Mercado Livre'} />
          </Col>
        </Row>
        {/* <Row>
          <Col sm={12} xs={12}>
            <Label marginBottom text={'Vendedor Mercao Livre:'} />
            <Form.Select
              size="lg"
              value={this.state.sellerML}
              onChange={(e) => this.handleChange('seller', e)}>
              {this.state.options.map((option, idx) => (
                <option key={idx} value={option.id_ml}>
                  {option.nickname}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row> */}
        <Row className="justify-content-center">
          <Col className="mt-3">
            <MUIDataTable
              title={'Bonificação/Cobranças : 05/10 - 06/11'}
              data={data}
              columns={columns}
              options={optionsMui}
            />
          </Col>
        </Row>
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center"></Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        admin
        body={this.body}
        footer={this.footer}
        modalState={modalState}
        loadingState={loadingState}
        alertScreenState={alertScreenState}
      />
    );
  }
}

ProductsList.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default ProductsList;
