import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Screen, SubHeader, Text } from '../../../components';
import { LeftBracketIcon } from './styles';

const { defaultStates } = require('../../../utils/defaults');

class Error extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
    };

    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
  }

  goBack = () => {
    this.props.history.push('/admin');
  };

  header() {
    return (
      <>
        <SubHeader back onClick={this.goBack} />
      </>
    );
  }

  body() {
    return (
      <>
        <Row className={'pb-3 pt-lg-5 mt-lg-5 pt-5 mt-4 ps-4 ps-lg-0'}>
          <Col
            className={'d-flex mb-3 mb-lg-4 mt-lg-4 mt-3'}
            lg={{ span: 8, offset: 4 }}
            sm={{ span: 10, offset: 2 }}>
            <LeftBracketIcon />
            <Text heading1 noWrap title={{ one: 'ERRO', two: 'PÁGINA NÃO ENCONTRADA' }} />
          </Col>
        </Row>
        <Row className={'pb-lg-5 mb-lg-5 pb-5 mb-4 ps-4 ps-lg-0'}>
          <Col
            className={'justify-content-center pr-0 mb-lg-4'}
            lg={{ span: 8, offset: 4 }}
            sm={{ span: 10, offset: 2 }}>
            <Text paragraph3 text={'Desculpe, a página que você procura não foi encontrada.'} />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { loadingState } = this.state;

    return <Screen errorPage body={this.body} header={this.header} loadingState={loadingState} />;
  }
}

Error.propTypes = {
  history: PropTypes.object,
};

export default Error;
