import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax, bootstrapMin, device } from '../../utils/devices';

import scroll from '../../assets/icons/admin/scroll.png';

const defaultButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'GT Walsheim Pro Bold', serif !important;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  width: 202px;
  height: 41px;
  border: 1px solid ${({ sec }) => (sec ? colors.primaryThree : colors.primaryOne)};
  color: ${({ sec }) => (sec ? colors.primaryThree : colors.primaryOne)};
  border-radius: 24px;
  white-space: nowrap;
  text-decoration: none;
`;

export const Simple = styled(defaultButton)`
  font-size: 18px;
  width: 303px;
  height: 48px;
  margin: 12px 0;
  background-color: ${({ danger }) => (danger ? '#ff1b1bed' : 'transparent')};
  color: ${({ danger }) => (danger ? colors.white : colors.primaryOne)};
  padding: 20px;

  @media ${bootstrapMax.lg} {
    width: 100%;
    max-width: 303px;
    min-width: 130px;
  }
`;

export const styleButton = styled.button`
  // text-transform: uppercase;
  display: flex;
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
`;

export const SimpleButton = styled(styleButton)`
  padding: 15px 30px;
  font-size: 24px;
  line-height: 28px;
`;

export const LinkButton = styled(styleButton)`
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-self: center;

  @media ${bootstrapMin.xxl} {
    position: relative;
    left: 6px;
  }
`;

export const BackButton = styled(NavLink)`
  font-size: 14px;
  line-height: 15px;
`;

export const CloseButton = styled(styleButton)`
  ${({ img }) =>
    img
      ? `
        background-image: url(${img});
        background-repeat: no-repeat;
        background-size: cover;`
      : ''}
  margin: 10px;
`;

export const BackButtonIcon = styled(styleButton)`
  ${({ img }) =>
    img
      ? `
        background-image: url(${img});
        background-repeat: no-repeat;
        background-size: cover;`
      : ''}
  margin: 0 10px
`;

export const DownloadButton = styled(styleButton)`
  ${({ img }) =>
    img
      ? `
        background-image: url(${img});
        background-repeat: no-repeat;
        background-size: cover;
        `
      : ''}
`;

export const SearchButton = styled(styleButton)`
  ${({ img }) =>
    img
      ? `
        background-image: url(${img});
        background-repeat: no-repeat;
        background-size: cover;`
      : ''};
`;

export const DownloadButtonApp = styled.img`
  width: 20px;
`;

export const SearchIcon = styled.img`
  width: 20px;
`;

export const SearchIconAdmin = styled.img`
  width: 20px;
  margin-right: 10px;
`;

export const SearchAdmin = styled(SimpleButton)`
  color: ${colors.white};
  padding: 10px;
  height: 40px;
  font-size: 20px;
  line-height: 20px;
  width: 100%;

  @media ${device.mobileL} {
    margin-top: 25px;
  }
`;

export const BackText = styled.div`
  margin-left: 30px;
  font-size: 14px;
  text-transform: uppercase;
  color: #707070;
  font-weight: bold;
  line-height: 13px;
}
`;

export const UpButton = styled.a`
  cursor: pointer;
  background-image: url(${scroll});
  width: 41px;
  height: 41px;
  background-repeat: no-repeat;
  position: fixed;
  bottom: 20px;
  right: 43px;

  @media ${bootstrapMax.sm} {
    width: 34px;
    height: 34px;
    background-size: 34px;
    margin-right: 17px;
    right: 25px;
    bottom: 260px;
  }

  @media ${bootstrapMin.xxl} {
    right: auto;
    bottom: 220px;
  }
`;

export const GreenButton = styled(Simple)`
  background-color: ${colors.white};
  font-family: 'Trade Gothic LT Bold' !important;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  height: ${({ height }) => (height ? `${height}px` : '53px')};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.secondaryThree};
  font-weight: bold;
  border: 1px solid ${colors.secondaryThree};
  border-radius: 8px;
  padding: 0 15px;
  transition: 0.3s;

  ${({ success }) =>
    success
      ? `background-color: ${colors.primaryOne} !important;
       color: ${colors.white} !important;
       transition: 0.3s;
       `
      : 'transition: 0.3s;'}

  ${({ error }) =>
    error
      ? `background-color: ${colors.red} !important;
         color: ${colors.white} !important;
         transition: 0.3s;
         `
      : 'transition: 0.3s;'}

  &:hover {
    background-color: #358e2e;
    color: ${colors.white};
  }

  @media ${bootstrapMax.md} {
    width: ${({ widthMobile }) => (widthMobile ? `${widthMobile}px` : 'auto')};
    height: 47px;
    font-size: 14px;
  }

  @media (max-width: 374px) {
    white-space: normal;
    height: auto;
    min-height: 47px;
  }
`;

export const GreenButtonSearch = styled(Simple)`
  background-color: #358e2e;
  font-family: 'Trade Gothic LT Bold' !important;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  height: 45px;
  font-size: 16px;
  margin-left: 90px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.white};
  font-weight: bold;
  border: 1px solid ${colors.secondaryThree};
  border-radius: 8px;

  @media ${bootstrapMax.sm} {
    display: none;
  }

  @media ${bootstrapMax.md} {
    margin-left: -61px;
  }
`;
