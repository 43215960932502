import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SelectableTitleCard from '../SelectableTitleCard';

class SelectableCardList extends Component {
  constructor(props) {
    super(props);
    var selected = 0;

    var initialState = {
      selected: selected,
    };

    this.state = initialState;
  }

  async componentDidMount() {
    const sellerML = localStorage.getItem('sellerML');
    this.setState({ sellerML: parseInt(sellerML) });
  }

  onItemSelected(index) {
    this.setState((prevState, props) => {
      this.setState({ sellerML: props.contents[index].id_ml });
      localStorage.setItem('sellerML', props.contents[index].id_ml);
      props.onChange(index);
      return {
        selected: index,
      };
    });
  }

  render() {
    var { contents } = this.props;

    var content = contents.map((cardContent, i) => {
      var { nickname, email, selected, id_ml } = cardContent;
      selected = this.state.sellerML === id_ml;
      return (
        <SelectableTitleCard
          key={i}
          title={nickname}
          description={email}
          selected={selected}
          onClick={(e) => this.onItemSelected(i)}
        />
      );
    });
    return <div className="cardlist">{content}</div>;
  }
}

SelectableCardList.propTypes = {
  //props
  nickname: PropTypes.string,
  description: PropTypes.string,
  selected: PropTypes.object,
  onClick: PropTypes.func,
  contents: PropTypes.array,
  onChange: PropTypes.func,
};

export default SelectableCardList;
