import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Screen, Text, Input, TableAdmin, Label } from '../../../components';
import { Row, Col, Form } from 'react-bootstrap';
import MUIDataTable from 'mui-datatables';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel'

import announcementService from '../../../services/announcements';
import sellerService from '../../../services/seller';

// import { CheckIcon, CancelIcon } from '@mui/icons-material/Check';

const { defaultStates, defaultAlertScreenOnClick } = require('../../../utils/defaults');

class AnnouncementsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerML: '',
      options: [],
      AnnouncementsList: [],
      ...defaultStates(),
    };

    this.modalOnHide = this.modalOnHide.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
    this.fillTable = this.fillTable.bind(this);
    this.fillSellers = this.fillSellers.bind(this);
  }

  async componentDidMount() {
    await this.fillSellers();
    const seller = localStorage.getItem('sellerML');
    if (seller !== 'null') {
      this.setState({ sellerML: seller });
    }
    await this.fillTable(this.state.sellerML);
  }

  handleChange(id, event) {
    if (id === 'seller') {
      this.setState({ sellerML: event.target.value });
      this.fillTable(event.target.value);
      localStorage.setItem('sellerML', event.target.value);
    }
  }

  async fillTable(sellerML, error = true) {
    this.setState({ loadingState: true });
    const response = await announcementService.admin.getList(sellerML);
    if (response.ok && response.data) {
      const data = response.data;
      this.setState({ AnnouncementsList: data });
    } else {
      if (error) {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  async fillSellers(error = true) {
    this.setState({ loadingState: true });

    const response = await sellerService.admin.getList();
    if (response.ok && response.data) {
      const data = response.data;
      this.setState({ sellerML: data[0].id_ml });
      this.setState({ options: data });
      data.push({ id: 0, id_ml: '0000000000', nickname: 'Todas', email: 'todas' });
    } else {
      if (error) {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  modalOnHide() {
    const { modalState } = this.state;
    modalState.toggle = false;
    this.setState(modalState);
    this.fillTable(false, this.state.sellerML);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  body() {
    const onCellClick = ({ id }) => this.props.history.push(`/admin/anuncio/${id}`);
    const { sellerML, options } = this.state;

    const columns = [
      {
        name: 'id',
        options: {
          display: 'excluded',
        },
      },
      {
        name: 'new_quantity',
        options: {
          display: 'excluded',
        },
      },
      {
        name: 'new_price',
        options: {
          display: 'excluded',
        },
      },
      {
        name: 'initial_price',
        options: {
          display: 'excluded',
        },
      },
      {
        name: 'id_ml',
        label: 'Código',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'title',
        label: 'Título',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'price',
        label: 'Valor',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'available_quantity',
        label: 'estoque',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'allowed_quantity',
        label: 'Editados',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <>
                {
                  value && tableMeta.rowData[1] && tableMeta.rowData[2] && tableMeta.rowData[3] ? <CheckIcon color="primary" fontSize="small" /> :
                    <CancelIcon color="primary" fontSize="small" />
                }
              </>
            );
          }
        }
      }
    ];

    const optionsMui = {
      filterType: 'checkbox',
      selectableRows: 'none',
      responsive: 'standard',
      print: false,
      onRowClick: (rowData) => {
        this.props.history.push(`/admin/anuncio/${rowData[0]}`);
      },
    };

    return (
      <>
        <Row align={'center'}>
          <Col>
            <Text title text={'Anúncios'} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <Label marginBottom text={'Vendedor Mercado Livre:'} />
            <Form.Select
              size="lg"
              value={this.state.sellerML}
              onChange={(e) => this.handleChange('seller', e)}>
              {this.state.options.map((option, idx) => (
                <option key={idx} value={option.id_ml}>
                  {option.nickname}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="mt-3">
            <MUIDataTable
              title={'Anúncios'}
              data={this.state.AnnouncementsList}
              columns={columns}
              options={optionsMui}
            />
          </Col>
        </Row>
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center"></Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        admin
        body={this.body}
        footer={this.footer}
        modalState={modalState}
        loadingState={loadingState}
        alertScreenState={alertScreenState}
      />
    );
  }
}

AnnouncementsList.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default AnnouncementsList;
