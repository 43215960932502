import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SelectableCardList from '../SelectableCardList';
import { StyleCardAccountant } from './styles';

class CardAccountant extends Component {
  constructor(props) {
    super(props);
  }

  onListChanged(selected) {
    this.setState({
      selected: selected,
    });
  }

  render() {
    return (
      <StyleCardAccountant>
        <div className="column">
          <h3 className="title">{this.props.title}</h3>
          <SelectableCardList
            contents={this.props.cardContents}
            onChange={this.onListChanged.bind(this)}
          />
        </div>
      </StyleCardAccountant>
    );
  }
}

CardAccountant.propTypes = {
  //props
  title: PropTypes.string,
  selected: PropTypes.string,
  cardContents: PropTypes.array,
};

export default CardAccountant;
